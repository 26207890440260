import { Injectable, OnDestroy } from '@angular/core';
import { Firestore, doc, docSnapshots } from '@angular/fire/firestore';
import { BehaviorSubject, Subscription, Observable } from 'rxjs';
import { UserService } from 'src/app/auth/services/user.service';
import { OrganizationConfig, OrganizationConfigs } from '../models/organization/config';
import { OrganizationService } from './organization.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationConfigService implements OnDestroy {
  private configSubject = new BehaviorSubject<OrganizationConfig>(new OrganizationConfig());
  private subscriptions: Subscription[] = [];

  constructor(
    private userService: UserService,
    private firestore: Firestore,
    private organizationService: OrganizationService
  ) {
    this.userService.orgUserAsObservable().subscribe(user => {
      //console.log(user)
      if (user?.orgId) {
        const orgId = this.organizationService.getCurrentOrganizationId() || user.orgId;
        this.loadConfig(orgId);
      } else {
        this.resetConfig();
      }
    });
  }

  /** ✅ Returns the current organization config */
  getOrganizationConfig(): OrganizationConfig {
    return this.configSubject.value;
  }

  /** ✅ Observable to subscribe to config updates */
  configAsObservable(): Observable<OrganizationConfig> {
    return this.configSubject.asObservable();
  }

  private configLoadedSubject = new BehaviorSubject<boolean>(false);
  configLoaded$ = this.configLoadedSubject.asObservable();


  /** 🔹 Fetches all config documents at once and updates the observable */
  private loadConfig(orgId: string): void {
    this.unsubscribeAll(); // Unsubscribe from any existing subscriptions first

    const configPaths = {
      activeModules: OrganizationConfigs.ActiveModules,
      integration: OrganizationConfigs.Integration,
      orderLabels: OrganizationConfigs.OrderLabels,
      orderStatusCodes: OrganizationConfigs.OrderStatusCodes,
      splitInboundOrderArticles: OrganizationConfigs.SplitInboundOrderArticles,
      warehouseOrdersManagement: OrganizationConfigs.WarehouseOrdersManagement
    };

    const fetchedConfigs = new Map<string, boolean>();
    Object.keys(configPaths).forEach(key => fetchedConfigs.set(key, false));

    Object.entries(configPaths).forEach(([configName, ConfigClass]) => {
      const configRef = doc(this.firestore, `organizations/${orgId}/config/${configName}`);
      const sub = docSnapshots(configRef).subscribe(snapshot => {
        if (snapshot.exists()) {
          const updatedConfig = { 
            ...this.configSubject.value, 
            [configName]: new ConfigClass(snapshot.data()) 
          };
          this.configSubject.next(updatedConfig);
          fetchedConfigs.set(configName, true);

          // ✅ Check if ALL configs are fetched, then mark as fully loaded
          if ([...fetchedConfigs.values()].every(v => v)) {
            this.configLoadedSubject.next(true);  // ✅ Marks config as fully loaded
          }
        } else {
          console.warn(`OrganizationConfigService: No config found for ${configName}`);
        }
      });

      this.subscriptions.push(sub);
    });
  }

  /** 🔹 Resets config when no valid orgId exists */
  private resetConfig(): void {
    this.unsubscribeAll();
    this.configSubject.next(new OrganizationConfig());
  }

  /** ✅ Unsubscribes from all active Firestore listeners */
  private unsubscribeAll(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.subscriptions = [];
  }

  /** ✅ Ensures cleanup when the service is destroyed */
  ngOnDestroy(): void {
    this.unsubscribeAll();
  }
}
